<template>
  <div style="padding-bottom: 100px">
    <div class="login d-flex flex-column justify-content-around">
      <div class="message-logo d-flex justify-content-center">
        <div>
          <div class="box-right">ENTER YOUR DATA BELOW</div>
        </div>
        <div><img src="../assets/img/logo-verify.svg" alt="" /></div>
      </div>

      <div
        v-if="loading"
        class="d-flex justify-content-center"
        style="height: 200px"
      >
        <b-spinner style="width: 3rem; height: 3rem"></b-spinner>
      </div>
      <form
        @submit="updateUser"
        class="align-items-center d-flex flex-column"
        v-else
      >
        <div
          class="
            d-flex
            register-section
            flex-column
            justify-content-center
            align-items-center
          "
        >
          <input
            placeholder="VOTRE E-MAIL..."
            v-model="email"
            class="mail-input"
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            title="Not valid email."
          />
          <input
            placeholder="YOUR NAME..."
            v-model="name"
            class="mail-input"
            type="text"
          />
        </div>
        <div class="container my-4">
          <h3 class="mb-2">When send notifications?</h3>

          <span>Morning</span>
          <b-form-timepicker
            minutes-step="30"
            v-model="times.morningNotificationTime"
            @input="onMorningTimeInput"
          ></b-form-timepicker>

          <span>Afternoon</span>
          <b-form-timepicker
            minutes-step="30"
            v-model="times.afternoonNotificationTime"
            @input="onAfternoonTimeInput"
          ></b-form-timepicker>

          <span>Evening</span>
          <b-form-timepicker
            minutes-step="30"
            v-model="times.eveningNotificationTime"
            @input="onEveningTimeInput"
          ></b-form-timepicker>
        </div>
        <button type="submit" class="main-button">Modifier</button>
        <a href="/pricing">Planifier</a>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      name: "",
      times: {
        morningNotificationTime: "09:00:00",
        afternoonNotificationTime: "13:00:00",
        eveningNotificationTime: "19:00:00",
      },
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    const user = (await this.$http.account.getProfile()).data;

    this.email = user.email;
    this.name = user.name;

    if (user.morningNotificationTime) {
      this.times.morningNotificationTime = user.morningNotificationTime;
    }

    if (user.afternoonNotificationTime) {
      this.times.afternoonNotificationTime = user.afternoonNotificationTime;
    }

    if (user.eveningNotificationTime) {
      this.times.eveningNotificationTime = user.eveningNotificationTime;
    }
    this.loading = false;
  },
  methods: {
    async updateUser(e) {
      e.preventDefault();
      if (e.target.checkValidity()) {
        await this.$http.account.updateUser({
          email: this.email,
          name: this.name,
          ...this.times,
        });

        const user = (await this.$http.account.getProfile()).data;

        this.$store.commit("UPDATE_USER", user);

        this.$router.push("/profile");
      } else {
        e.target.reportValidity();
      }
    },
    onMorningTimeInput(time) {
      const hour = Number(time.split(":")[0]);

      if (hour >= 12) {
        setTimeout(() => (this.times.morningNotificationTime = "11:30:00"), 0);
      }

      if (hour < 4) {
        setTimeout(() => (this.times.morningNotificationTime = "04:00:00"), 0);
      }
    },
    onAfternoonTimeInput(time) {
      const hour = Number(time.split(":")[0]);

      if (hour >= 18) {
        setTimeout(
          () => (this.times.afternoonNotificationTime = "17:30:00"),
          0
        );
      }

      if (hour < 12) {
        setTimeout(
          () => (this.times.afternoonNotificationTime = "12:00:00"),
          0
        );
      }
    },
    onEveningTimeInput(time) {
      const hour = Number(time.split(":")[0]);

      if (hour >= 23) {
        setTimeout(() => (this.times.eveningNotificationTime = "22:30:00"), 0);
      }

      if (hour < 18) {
        setTimeout(() => (this.times.eveningNotificationTime = "18:00:00"), 0);
      }
    },
  },
};
</script>
